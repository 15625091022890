<template>
  <div>
    <strong v-if="location === 'general-info'">{{
      trans("examination.aiPrioritizedCaseLabel")
    }}</strong>
    <div v-if="priority">
      <span
        class="badge ai-prioritization-icon"
        :class="priorityClass"
        :title="trans('general.caseAiPrioritizedTitle')"
        >{{ priorityText }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priority: String,
    location: String,
  },
  computed: {
    priorityClass() {
      switch (this.priority) {
        case "low_prio":
          return "badge-success";
        case "medium_prio":
          return "badge-warning";
        case "high_prio":
          return "badge-danger";
        default:
          return "";
      }
    },
    priorityText() {
      switch (this.priority) {
        case "low_prio":
          return this.trans("general.aiLow");
        case "medium_prio":
          return this.trans("general.aiMedium");
        case "high_prio":
          return this.trans("general.aiHigh");
        default:
          return "AI";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-prioritization-icon {
  font-size: 75% !important;
  font-weight: normal;
  padding-left: 0.3em;

  &.badge-danger {
    background-color: #e5625e !important;
  }

  &.badge-warning {
    background-color: #fabc2a !important;
  }

  &.badge-success {
    background-color: #53dd6c !important;
  }
}
</style>
